<template>
  <v-card fluid>
    <v-toolbar color="primary" dark class="mb-2">
      <v-toolbar-title class="text-h5" elevation="20">
        Détails de la carte
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div v-if="dataCarte.carte" class="mt-2" elevation="2" outlined dense hide-details>
      <v-card-title
        v-if="dataCarte.carte.assignement && dataCarte.carte.assignement.assigned"
      >
        <!-- ajouté une row si tache assigné -->
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              :value="dataCarte.carte.assignement.assignedTo"
              label="Assigné à"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title>
        <v-row
          v-if="
            dataCarte.carte &&
            dataCarte.carte.linkedCard &&
            dataCarte.carte.linkedCard !== ''
          "
        >
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              :value="dataCarte.carte.libelle"
              label="Libellé"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              :value="dataCarte.carte.linkedCard"
              label="Carte liée"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              :value="dataCarte.carte.libelle"
              label="Libellé"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            :cols="dataCarte.carte.duree ? 5 : 6"
            :sm="dataCarte.carte.duree ? 5 : 6"
            :md="dataCarte.carte.duree ? 5 : 6"
          >
            <v-text-field
              :value="
                dataCarte.carte.module ? formatData(dataCarte.carte.module) : 'Aucun'
              "
              label="Module"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            :cols="dataCarte.carte.duree ? 5 : 6"
            :sm="dataCarte.carte.duree ? 5 : 6"
            :md="dataCarte.carte.duree ? 5 : 6"
          >
            <v-text-field
              :value="
                dataCarte.carte.sous_module
                  ? formatData(dataCarte.carte.sous_module)
                  : 'Aucun'
              "
              label="Sous-module"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <!-- si duree est vrai -->
          <v-col cols="1" sm="1" md="2" v-if="dataCarte.carte.duree">
            <v-text-field
              :value="dataCarte.carte.duree.duree + ' ' + dataCarte.carte.duree.unite"
              label="Durée estimée"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              :value="formatData(dataCarte.carte.userCreate)"
              label="Demandeur"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              :value="dataCarte.carte.devType"
              label="Type"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              :value="dataCarte.carte.originType"
              label="Origine"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- si urgentRequestDate -->
        <v-row
          v-if="
            dataCarte.carte.urgentRequestDate &&
            dataCarte.carte.urgentRequestDate != 'null'
          "
        >
          <v-col cols="12" sm="12" md="12">
            <!-- en rouge -->
            <v-text-field
              :value="dataCarte.carte.urgentRequestDate | formatDate"
              label="Date de demande urgente souhaitée"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              :value="dataCarte.carte.description | escapeHTML"
              label="Description"
              outlined
              dense
              hide-details
              readonly
              rows="2"
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- si pj -->
        <v-divider
          class="my-4"
          v-if="dataCarte.carte.images && dataCarte.carte.images.length > 0"
        ></v-divider>
        <v-row
          v-if="dataCarte.carte.images && dataCarte.carte.images.length > 0"
          class="d-flex justify-center"
        >
          <v-col cols="8" sm="8" md="8">
            <v-row>
              <v-col
                v-for="(img, index) in dataCarte.carte.images"
                :key="index"
                cols="4"
                sm="4"
                md="4"
              >
                <v-img
                  :src="generateLogoUrl(img)"
                  width="100%"
                  height="100%"
                  contain
                  @click="openDialog(img, index)"
                  style="cursor: pointer"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close"> Fermer </v-btn>
      </v-card-actions>
    </div>
    <!-- Dialog for enlarged image -->
    <v-dialog v-model="dialogImg" max-width="80%">
      <v-card>
        <v-card-title class="headline">
          {{ dialogImageName }}
        </v-card-title>
        <v-card-text>
          <v-img :src="dialogImageUrl" width="100%" max-height="60vh" contain></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogImg = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import SupportVisionBus from "../SupportVisionBus";
import * as Vuex from "vuex";
import moment from "moment";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";

export default {
  name: "carteDetails",
  components: {
    EditDev: () => import("@/Components/Views/SupportVision/Cartes/editDev"),
    DeleteDev: () => import("@/Components/Views/SupportVision/Cartes/devDelete"),
  },
  data() {
    return {
      carte: {},
      location: "",
      dialogImg: false,
      dialogImageUrl: "",
      dialogImageName: "",
    };
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    // echappempent des balises html
    escapeHTML(value) {
      return value.replace(/<[^>]*>/g, "");
    },
  },
  computed: {
    ...Vuex.mapState(["taskId"]),
    getColorByDemande() {
      return (demande) => {
        if (demande == "Travaux unique") {
          return "#43A047";
        } else if (demande == "Release") {
          return "#1E88E5";
        } else if (demande == "Urgent") {
          return "#E53935";
        }
      };
    },
    getColorByStatus() {
      return (status) => {
        if (status == "en_cours") {
          return "orange";
        } else if (status == "terminé") {
          return "green";
        } else if (status == "prise_en_compte") {
          return "grey";
        } else if (status == undefined || status == "") {
          return "black";
        }
      };
    },
    dataCarte() {
      return this.carte;
    },
  },
  watch: {
    taskId() {
      // si le taskId change, on recharge les données
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      const res = await DeveloppementService.getDevById(this.taskId);
      this.carte = res.data;
      this.location = res.config.baseURL + res.config.url;
      this.location = this.location.replace(this.taskId, "");
    },
    close() {
      this.$emit("close");
    },
    generateLogoUrl(img) {
      if (img && img.filename && img.mimetype) {
        return `${this.location}/read-file?id=${img.filename}&mime=${img.mimetype}`;
      }
      return "";
    },
    formatData(data) {
      // si data est un tableau
      if (Array.isArray(data)) {
        return data.join();
      } else {
        return data && data.replace(/[\][\]"]+/g, "");
      }
    },
    openDialog(img, index) {
      this.dialogImg = true;
      this.dialogImageUrl = this.generateLogoUrl(img);
      this.dialogImageName = img.name;
    },
  },
  async mounted() {
    await this.loadData();
    SupportVisionBus.$on("open-details", this.loadData);
  },
  beforeDestroy() {
    SupportVisionBus.$off("open-details", this.loadData);
  },
};
</script>

<style scoped>
.col {
  overflow: auto;
  height: calc(90vh - 200px);
  display: flex;
  flex-direction: column;
  /* barre de scroll */
  scrollbar-width: thin;
  scrollbar-color: #7b4e8e #f5f5f5;
}

.draggable-list {
  min-height: 10vh;
}

.draggable-list > div {
  cursor: pointer;
}

.biais-trait::after {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 13px;
  /* Ajustez la position du trait */
  width: 2px;
  /* Longueur du trait */
  height: 20px;
  background-color: gray;
  /* Couleur du trait */
  transform: skewX(-50deg);
  /* Inclinaison du trait */
}

.biais-trait::before {
  content: "";
  position: absolute;
  bottom: 3px;
  right: 10px;
  width: 2px;
  height: 14px;
  background-color: gray;
  transform: skewX(-50deg);
}
</style>
