var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"text-h5",attrs:{"elevation":"20"}},[_vm._v(" Détails de la carte ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.dataCarte.carte)?_c('div',{staticClass:"mt-2",attrs:{"elevation":"2","outlined":"","dense":"","hide-details":""}},[(_vm.dataCarte.carte.assignement && _vm.dataCarte.carte.assignement.assigned)?_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.assignement.assignedTo,"label":"Assigné à","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1)],1):_vm._e(),_c('v-card-title',[(
          _vm.dataCarte.carte &&
          _vm.dataCarte.carte.linkedCard &&
          _vm.dataCarte.carte.linkedCard !== ''
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.libelle,"label":"Libellé","outlined":"","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.linkedCard,"label":"Carte liée","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.libelle,"label":"Libellé","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.dataCarte.carte.duree ? 5 : 6,"sm":_vm.dataCarte.carte.duree ? 5 : 6,"md":_vm.dataCarte.carte.duree ? 5 : 6}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.module ? _vm.formatData(_vm.dataCarte.carte.module) : 'Aucun',"label":"Module","outlined":"","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":_vm.dataCarte.carte.duree ? 5 : 6,"sm":_vm.dataCarte.carte.duree ? 5 : 6,"md":_vm.dataCarte.carte.duree ? 5 : 6}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.sous_module
                ? _vm.formatData(_vm.dataCarte.carte.sous_module)
                : 'Aucun',"label":"Sous-module","outlined":"","dense":"","hide-details":"","readonly":""}})],1),(_vm.dataCarte.carte.duree)?_c('v-col',{attrs:{"cols":"1","sm":"1","md":"2"}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.duree.duree + ' ' + _vm.dataCarte.carte.duree.unite,"label":"Durée estimée","outlined":"","dense":"","hide-details":"","readonly":""}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.formatData(_vm.dataCarte.carte.userCreate),"label":"Demandeur","outlined":"","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.devType,"label":"Type","outlined":"","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.dataCarte.carte.originType,"label":"Origine","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1),(
          _vm.dataCarte.carte.urgentRequestDate &&
          _vm.dataCarte.carte.urgentRequestDate != 'null'
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"value":_vm._f("formatDate")(_vm.dataCarte.carte.urgentRequestDate),"label":"Date de demande urgente souhaitée","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"value":_vm._f("escapeHTML")(_vm.dataCarte.carte.description),"label":"Description","outlined":"","dense":"","hide-details":"","readonly":"","rows":"2"}})],1)],1),(_vm.dataCarte.carte.images && _vm.dataCarte.carte.images.length > 0)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),(_vm.dataCarte.carte.images && _vm.dataCarte.carte.images.length > 0)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('v-row',_vm._l((_vm.dataCarte.carte.images),function(img,index){return _c('v-col',{key:index,attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.generateLogoUrl(img),"width":"100%","height":"100%","contain":""},on:{"click":function($event){return _vm.openDialog(img, index)}}})],1)}),1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.close}},[_vm._v(" Fermer ")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.dialogImg),callback:function ($$v) {_vm.dialogImg=$$v},expression:"dialogImg"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.dialogImageName)+" ")]),_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.dialogImageUrl,"width":"100%","max-height":"60vh","contain":""}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogImg = false}}},[_vm._v("Fermer")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }